.MuiSwitch-thumb {
    background-color: #1976d2 !important;
}

.MuiSwitch-track {
    background-color: #1976d2 !important;
}

.Mui-checked .MuiSwitch-thumb {
    background-color: #2e7d32 !important;
}

.Mui-checked + .MuiSwitch-track {
    background-color: #2e7d32 !important;
}